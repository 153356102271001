import React, { useEffect, useState, useRef } from 'react';
import Block from '../../../adapters/helpers/Block';
import { mediaQueryHOC } from '../../../adapters/helpers/Hooks';
import { AnalyticsConstants } from '../../../adapters/helpers/ConstantsGA';
import {
  StartGuideBlockConstants,
  SpotlightWaitlistConstants,
} from '../../../adapters/helpers/Constants';
import Modal from 'react-modal';
import { mixtureFormRegisterIO2 } from './restapi';
import { checkPattern } from '../../../adapters/helpers/Utils';
import {
  Icon,
  Constants,
  customStylesInterestitialLegalRevamp,
  CheckboxInput,
} from '../../Mixture/View/exportComponent';
import {
  mixtureGraphQL,
  filterMethod,
} from '../../Mixture/View/exportComponent';

const StartGuideSignupForm = (props) => {
  const block = new Block(props);
  const { isXSmall, isSmall } = props;
  const blockRef = useRef(null);
  const firstLegalModalRef = useRef(null);
  const className = block.getFieldValue(StartGuideBlockConstants.className);
  const longText = block.getFieldValue(StartGuideBlockConstants.longText);
  const buttonText = block.getFieldValue(StartGuideBlockConstants.buttonText);
  const isNewSignUp = block.getFieldValue(StartGuideBlockConstants.isNewSignUp);
  const formLabels = block.getFieldValue(StartGuideBlockConstants.formLabels);
  const [isThankyouModalOpen, setIsThankyouModalOpen] = useState(false);
  const formLabel = formLabels[0];
  const anchorId = block.getFieldValue(StartGuideBlockConstants.anchorId);
  const thankYouTitle = block.getFieldValue(
    StartGuideBlockConstants.thankYouTitle
  );
  const thankYouDescription = block.getFieldValue(
    StartGuideBlockConstants.thankYouDescription
  );
  const thankYouDisclaimer = block.getFieldValue(
    StartGuideBlockConstants.thankYouDisclaimer
  );
  const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
  const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
  const modalLabelFirst = block.getFieldValue(
    StartGuideBlockConstants.modalLabelFirst
  );
  const modalLabelSecond = block.getFieldValue(
    StartGuideBlockConstants.modalLabelSecond
  );
  const legalText = block.getFieldValue(StartGuideBlockConstants.legalText);
  const closeModalLabel = block.getFieldValue(
    SpotlightWaitlistConstants.closeModalLabel
  )?.fields?.text;
  const welcomeText = block.getFieldValue(StartGuideBlockConstants.welcomeText);
  const [
    hasAcceptedTermsAndConditions,
    setHasAcceptedTermsAndConditions,
  ] = useState(false);
  const emailLegalText = block.getFieldValue(
    SpotlightWaitlistConstants.emailLegalText
  );
  const emailExceedLimitLabel = block.getFieldValue(StartGuideBlockConstants.emailExceedLimit);
  const serverError = block.getFieldValue(StartGuideBlockConstants.serverError);
  const desktopButtonAsset = block.getFieldValue(
    StartGuideBlockConstants.desktopButtonAsset
  );
  const mobileButtonAsset = block.getFieldValue(
    StartGuideBlockConstants.mobileButtonAsset
  );

  const [buttonClicked, setbuttonClicked] = useState(false);
  const [emailInput, setEmailInput] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [emptyInputError, setEmptyInputError] = useState(false);
  const [incorrectInputError, setIncorrectInputError] = useState(false);
  const [serverSideErrorMessage, setServerSideErrorMessage] = useState('');
  const [optinsData, setOptinsData] = useState();

  const checkIsMobile = () => {
    return isXSmall || isSmall ? true : false;
  };

  const registerLegalModalOpen = () => {
    return setTimeout(() => {
      return setLegalModals();
    }, 500);
  };

  const setLegalModals = () => {
    if (blockRef.current) {
      let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
      if (firstModalId) {
        firstModalId.addEventListener('click', (e) => {
          e.preventDefault();
          setisFirstLegalModalOpen(true);
        });
      }

      let secondModalId = blockRef.current.querySelector(
        '#openSecondLegalModal'
      );
      if (secondModalId) {
        secondModalId.addEventListener('click', (e) => {
          e.preventDefault();
          setIsSecondLegalModalOpen(true);
        });
      }
    }
  };

  const openPopUp = () => {
    setbuttonClicked(true);
    registerLegalModalOpen();
    if (
      document.cookie.includes(`${SpotlightWaitlistConstants.IO2Registered}=ok`)
    ) {
      setIsThankyouModalOpen(true);
    } else {
      renderFormContainer();
    }
  };

  const changeUserData = (evt, id) => {
    setEmailInput(evt.target.value);
  };

  const closeModal = () => {
    closeLegalModal();
    setIsThankyouModalOpen(false);
    setbuttonClicked(false);
  };

  const closeLegalModal = () => {
    setisFirstLegalModalOpen(false);
    setIsSecondLegalModalOpen(false);
  };

  useEffect(() => {
    mixtureGraphQL(false, true)
      .then((response) => {
        let optinsObj = filterMethod(response?.consumerActions, 'optIn');
        setOptinsData(optinsObj);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    setIsSubmitting(true);
    setIsLoader(true);
    const inputs = form.querySelectorAll('input');
    if (inputs[0].value.length === 0) {
      setEmptyInputError(true);
    } else if (inputs[0].hasAttribute('pattern')) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(inputs[0].value)) {
        setIncorrectInputError(true);
      }
    }

    const email = form?.email.value;
    if (!emptyInputError && !incorrectInputError) {
      let arguObj = {
        email: email,
        optinsData: optinsData,
        emailOptInStatus: hasAcceptedTermsAndConditions,
      };

      await mixtureFormRegisterIO2(arguObj)
        .then((response) => {
          if (response?.status === 'SUCCESS') {
            setIsSubmitting(false);
            setIsLoader(false);
            document.cookie = `${SpotlightWaitlistConstants.IO2Registered}=ok;`;
            setIsThankyouModalOpen(true);
          } else {
            setIsSubmitting(false);
            setIsLoader(false);
            setServerSideErrorMessage(serverError);
            return response?.json();
          }
        })
        .then((response) => {
          if (
            response &&
            response.status &&
            response.code &&
            response.code === 'ALREADY_PARTICIPATED'
          ) {
            setServerSideErrorMessage(emailExceedLimitLabel);
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setIsLoader(false);
        });
    } else {
      console.error('Incorrect Input');
    }
  };

  const renderThankYouScreen = () => {
    return (
      <div
        className='ob-interstitialPopup-container-thankyou'
        id={anchorId}
        ref={blockRef}
      >
        {thankYouTitle && <h2 className='title'>{thankYouTitle}</h2>}
        {thankYouDescription && (
          <p className='description'>{thankYouDescription}</p>
        )}
        {thankYouDisclaimer && (
          <p
            className='disclaimer'
            dangerouslySetInnerHTML={{ __html: thankYouDisclaimer }}
          />
        )}
        <button
          aria-label={closeModalLabel}
          onClick={closeModal}
          className={'ob-spotlight-waitlist__close-modal'}
          type='button'
        >
          <Icon
            name={SpotlightWaitlistConstants.close}
            size='1.4'
            color={Constants.black}
          />
        </button>
      </div>
    );
  };

  const renderFormContainer = () => {
    return (
      <form
        className='ob-form-container'
        onSubmit={handleSubmit}
        ref={blockRef}
      >
        <p
          dangerouslySetInnerHTML={{ __html: welcomeText }}
          className={'disclaimer'}
        />
        <input
          type={formLabel.fields.type}
          pattern={formLabel?.fields?.validation?.fields?.pattern}
          required={formLabel?.fields?.validation?.fields?.required}
          id={formLabel?.fields?.id}
          name={formLabel?.fields?.id}
          value={emailInput}
          maxLength={formLabel?.fields?.maxLength}
          onChange={(evt) => changeUserData(evt, formLabel?.fields?.id)}
          className={'ob-form-input'}
          placeholder={formLabel.fields.label.fields.text}
        />
        <button className='ob-button'>Submit</button>
        {serverSideErrorMessage && <p>{serverSideErrorMessage}</p>}
        {emailLegalText && (
          <div className={'ob-interstitial-popup-emailLegalText'}>
            <CheckboxInput
              label={`<p>${emailLegalText}</p>`}
              name={SpotlightWaitlistConstants.checkBoxText}
              value={SpotlightWaitlistConstants.checkBoxText}
              checked={hasAcceptedTermsAndConditions}
              onChange={() =>
                setHasAcceptedTermsAndConditions(!hasAcceptedTermsAndConditions)
              }
            />
          </div>
        )}
        <div
          className='legalText'
          dangerouslySetInnerHTML={{ __html: legalText }}
        />
        <button
          aria-label={closeModalLabel}
          onClick={closeModal}
          className={'ob-spotlight-waitlist__close-modal'}
          type='button'
        >
          <Icon
            name={SpotlightWaitlistConstants.close}
            size='1.4'
            color={Constants.black}
          />
        </button>
      </form>
    );
  };

  if (isNewSignUp) {
    return (
      <>
        {checkIsMobile() ? (
          <div
            className={`${className} sign-up-block-mobile event_button_click`}
            data-action-detail={AnalyticsConstants.signUpEvent}
          >
            <button
              className='sign-up-button-mobile'
              onClick={() => openPopUp()}
            >
              {longText}
            </button>
            {buttonClicked && (
              <div className='ob-form-content'>
                {isThankyouModalOpen
                  ? renderThankYouScreen()
                  : renderFormContainer()}
              </div>
            )}
            {(modalLabelFirst || modalLabelSecond) && (
              <Modal
                isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                style={customStylesInterestitialLegalRevamp()}
                onRequestClose={closeLegalModal}
                closeTimeoutMS={250}
                overlayClassName={'event_button_click'}
                ref={firstLegalModalRef}
                portalClassName={`interestitial-popup-legal ${
                  isFirstLegalModalOpen
                    ? 'first-legal-popup'
                    : 'second-legal-popup'
                }`}
              >
                <div className='ob-spotlight-waitlist__modal-container-legal'>
                  {isFirstLegalModalOpen && modalLabelFirst && (
                    <p
                      className='description'
                      dangerouslySetInnerHTML={{ __html: modalLabelFirst }}
                    ></p>
                  )}

                  {isSecondLegalModalOpen && modalLabelSecond && (
                    <p
                      className='description'
                      dangerouslySetInnerHTML={{ __html: modalLabelSecond }}
                    ></p>
                  )}

                  <button
                    className='event_close_window ob-modal-close'
                    onClick={closeLegalModal}
                    aria-label={closeModalLabel}
                    type='button'
                  >
                    <Icon name='close' size='2.4' color='#000' />
                  </button>
                </div>
              </Modal>
            )}
          </div>
        ) : (
          <div
            className={`${className} sign-up-block event_button_click`}
            data-action-detail={AnalyticsConstants.signUpEvent}
          >
            <div className='sign-up-text'>
              <p>{longText}</p>
            </div>
            <button
              onClick={() => openPopUp()}
              className='ob-button sign-up-button event_button_click'
              data-action-detail={AnalyticsConstants.signUpEvent}
            >
              {buttonText}
            </button>
          </div>
        )}
        {buttonClicked && (
          <div className='ob-form-content'>
            {isThankyouModalOpen
              ? renderThankYouScreen()
              : renderFormContainer()}
          </div>
        )}
        {(modalLabelFirst || modalLabelSecond) && (
          <Modal
            isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
            style={customStylesInterestitialLegalRevamp()}
            onRequestClose={closeLegalModal}
            closeTimeoutMS={250}
            overlayClassName={'event_button_click'}
            ref={firstLegalModalRef}
            portalClassName={`interestitial-popup-legal ${
              isFirstLegalModalOpen ? 'first-legal-popup' : 'second-legal-popup'
            }`}
          >
            <div className='ob-spotlight-waitlist__modal-container-legal'>
              {isFirstLegalModalOpen && modalLabelFirst && (
                <p
                  className='description'
                  dangerouslySetInnerHTML={{ __html: modalLabelFirst }}
                ></p>
              )}

              {isSecondLegalModalOpen && modalLabelSecond && (
                <p
                  className='description'
                  dangerouslySetInnerHTML={{ __html: modalLabelSecond }}
                ></p>
              )}

              <button
                className='event_close_window ob-modal-close'
                onClick={closeLegalModal}
                aria-label={closeModalLabel}
                type='button'
              >
                <Icon name='close' size='2.4' color='#000' />
              </button>
            </div>
          </Modal>
        )}
      </>
    );
  } else {
    return (
      <>
        {checkIsMobile() ? (
          <div
            className={`${className} mobile-button event_button_click`}
            data-action-detail={AnalyticsConstants.signUpEvent}
          >
            <img src={mobileButtonAsset?.fields?.file?.url} />
          </div>
        ) : (
          <div
            className={`${className} desktop-button event_button_click`}
            data-action-detail={AnalyticsConstants.signUpEvent}
          >
            <img src={desktopButtonAsset?.fields?.file?.url} />
          </div>
        )}
      </>
    );
  }
};

export default mediaQueryHOC(StartGuideSignupForm);
