/*
* A common place to set, get and delete local storage items used in the login/register/profile process
* This reduces the risk of misspelling strings and making other mistakes
*/

import {JanrainConstants} from '../../../adapters/helpers/Constants';
import {redirectTo} from './redirect.helpers';

export const setProfileData = () =>{
  let userData = localStorage.getItem('janrainCaptureProfileData');
  if(userData) {
      const profileData = JSON.parse(userData);
      // Hardcoding value of marketingProgramNbr for Italy 
      const profileObject = {
        id:profileData.consumerID || profileData.uuid,
        marketingProgramNbr: process.env.MARKET && (process.env.MARKET.trim() =='it-it'||process.env.MARKET.trim() =='sv-se') ? process.env.PG_DATA_LAYER_USER_MARKETING_PROGRAM_NBR :profileData.marketingProgramNbr,
        sourceID: process.env.PG_DATA_LAYER_USER_SOURCE_ID,
        type:'member'
      }
      if(window.PGdataLayer && !window.PGdataLayer.user){
          window.PGdataLayer.user = {}
      }
      if (typeof Object.assign != 'function') {
        Object.assign = function(target) {
          'use strict';
          if (target == null) {
            throw new TypeError('Cannot convert undefined or null to object');
          }
      
          target = Object(target);
          for (var index = 1; index < arguments.length; index++) {
            var source = arguments[index];
            if (source != null) {
              for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                  target[key] = source[key];
                }
              }
            }
          }
          return target;
        };
      } else {
        Object.assign(window.PGdataLayer.user,profileObject)
      }
    } else {
      const profileObject = {
        marketingProgramNbr: process.env.PG_DATA_LAYER_USER_MARKETING_PROGRAM_NBR || '',
        sourceID: process.env.PG_DATA_LAYER_USER_SOURCE_ID,
        type:'anonymous'
      }
      if(window.PGdataLayer && !window.PGdataLayer.user){
        window.PGdataLayer.user = {}
    }
    if (typeof Object.assign != 'function') {
      Object.assign = function(target) {
        'use strict';
        if (target == null) {
          throw new TypeError('Cannot convert undefined or null to object');
        }
    
        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
          var source = arguments[index];
          if (source != null) {
            for (var key in source) {
              if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
              }
            }
          }
        }
        return target;
      };
    } else {
      Object.assign(window.PGdataLayer.user,profileObject)
    }

    }
}

const triggerEvent = (
    eventCategory,
    eventAction,
    eventLabel = '',
    callback,
  ) => {
    window.dataLayer.push({
      event: 'customEvent',
      GAeventCategory: eventCategory,
      GAeventAction: eventAction,
      GAeventLabel: eventLabel,
      GAeventValue: 0,
      GAeventNonInteraction: false,
      eventCallback: callback,
    })
  }

export const triggerLoginEvent = () => {
    // eslint-disable-next-line no-console
    try {
      triggerEvent(
        'event_crm_action',
        'event_profile_login_complete',
        'oralb_successful_login',
      )
    } catch (error) {
      console.error('GA is not loaded...')
    }
  }

export const triggerRegisiterEvent = () => {
    // eslint-disable-next-line no-console
    try {
      /** Promise to make sure Login is called only after registration
       *  dont use EventCallout
       */
      new Promise((resolve) => triggerEvent(
        'event_crm_action',
        'event_profile_register_complete',
        'oralb_successful_register',
        resolve(),
      )).then(() => {
        triggerEvent(
          'event_crm_action',
          'event_profile_login_complete',
          'oralb_successful_login',
        )
      })
    } catch (error) {
      console.error('GA is not loaded...')
    }
  }
  
export const triggerLogoutEvent = () => {
    if (!(window.PGdataLayer && window.PGdataLayer.user)) return
    if (!window.PGdataLayer.user.id) return
    // eslint-disable-next-line no-console
    try {
      triggerEvent(
        'event_crm_action',
        'event_profile_logout',
        'oralb_successful_logout',
      )
    } catch (error) {
      console.error('GA is not loaded...')
    }
  }
export const getOralbProductListFromLocalStorage = (uuid) => {
    let key = JanrainConstants.productListLocalStorageKeyPrefix + uuid;
    return localStorage.getItem(key) || '[]';
};

export const setOralbProductListToLocalStorage = (productList, uuid) => {
    let key = JanrainConstants.productListLocalStorageKeyPrefix + uuid;
    localStorage.setItem(key, productList);
};

export const getOralbBenefitPreferenceListFromLocalStorage = (uuid) => {
    let key = JanrainConstants.benefitListLocalStorageKeyPrefix + uuid;
    return localStorage.getItem(key) || '[]';
};

export const setOralbBenefitPreferenceListToLocalStorage = (benefitPreferenceList, uuid) => {
    let key = JanrainConstants.benefitListLocalStorageKeyPrefix + uuid;
    localStorage.setItem(key, benefitPreferenceList);
};

export const getIsCreatingAccountFromLocalStorage = () => {
    return localStorage.getItem('isCreatingAccount') || '';
};

export const setIsCreatingAccountToLocalStorage = value => {
    localStorage.setItem('isCreatingAccount', value);
};

export const deleteIsCreatingAccountFromLocalStorage = () => {
    localStorage.removeItem('isCreatingAccount');
};

export const getJanrainCaptureProfileDataFromLocalStorage = () => {
    return localStorage.getItem('janrainCaptureProfileData') || '{}';
};

export const getJanrainCaptureTokenExpiresFromLocalStorage = () => {
    return localStorage.getItem('janrainCaptureToken_Expires') || '';
};

export const getJanrainCaptureTokenFromLocalStorage = () => {
    return localStorage.getItem('janrainCaptureToken') || '';
};

export const deleteJanrainCaptureProfileDataFromLocalStorage = () => {
    localStorage.removeItem('janrainCaptureToken');
    localStorage.removeItem('janrainFailedLogins');
    localStorage.removeItem('janrainCaptureReturnExperienceData');
    localStorage.removeItem('janrainCaptureProfileData');
    localStorage.removeItem('janrainCaptureToken_Expires');
    localStorage.removeItem('janrainCaptureReturnExperienceData_Expires');
    localStorage.removeItem('janrainCaptureProfileData_Expires');
    localStorage.removeItem('janrainSessionTimeoutDate');
};

export const setSessionTimeoutFromLocalStorage = () => {
    let timeoutDate = addMinutes(new Date(), parseInt(process.env.JANRAIN_SESSION_TIMOUT_MIN));
    localStorage.setItem('janrainSessionTimeoutDate', timeoutDate);
};

export const removeSessionTimeoutFromLocalStorage = () => {
    localStorage.removeItem('janrainSessionTimeoutDate');
};

// session timeout layer added to invalidate janrain session if user is pass this timeout
export const validateCustomSessionTimeoutActive = () => {
    let sessionTimeout = localStorage.getItem('janrainSessionTimeoutDate');
    if(sessionTimeout) {
        let sessionTimeoutDate = new Date(sessionTimeout);
        if(!(sessionTimeoutDate > new Date())) {
            if (window?.janrain?.capture?.ui?.endCaptureSession) {
                window.janrain.capture.ui.endCaptureSession();
                redirectTo(process.env.JANRAIN_LOGIN_PATH);
            } else {
                deleteJanrainCaptureProfileDataFromLocalStorage();
                window.location.reload();
            }
        }
    }
};

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}
